:root {
	--color: #00bcd4;
	--amplify-primary-color: #00bcd4;
	--amplify-primary-shade: #00acc1;
	--amplify-primary-tint: #0097a7;
	--amplify-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	--amplify-red: #fff;

	--amplify-secondary-tint: #f50057;
	--box-shadow: none;
	--container-height: 600px !important;
}
:host {
	--box-shadow: none;
}
amplify-authenticator {
	--box-shadow: none;
}
amplify-button {
	border-radius: 6px;
}
